
.noselect {
  user-select: none;
}

.body {
  font-family: Georgia, serif;

  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  cursor: url('images/cursorIconLlama.png'),crosshair;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#pfp {
  margin: 1%;
  width: 10rem;
}

#pfp:hover {
  cursor: crosshair;
}

.round {
  border-radius: 50%;
}

.round-15 {
  border-radius: 15px;
}

.center {
  justify-content: center;
  text-align: center;
}

.blue-highlight {
  background-color: #61dafb;
}

.red-highlight {
  background-color: #d56262;
}

.gray-highlight {
  background-color: rgb(135, 135, 135);
}

.card-body {
  align-items: center;
  z-index: 3;
  font-size: 12px;
  padding-bottom: 2%;
  min-height: 50%;
}

.card {
  z-index: 3;
  align-items: center;
  background-color: #404348;
  width: var(--card-size);
  border-width: 5px;
  border-image: linear-gradient(5deg, #d56262 50%, rgb(212, 212, 212) 50%);
  border-image-slice: 2;

  padding-left: 0px;
  padding-right: 0px;
}

.card-hover {
  background-color: #404348;
  z-index: 3;
  width: var(--card-size);
  border-width: 5px;
  border-image: linear-gradient(185deg, #d56262 50%, rgb(212, 212, 212) 50%);
  border-image-slice: 2;

  padding-left: 0px;
  padding-right: 0px;
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 80%;
  }
  .card-body {
    width: 80%;
  }

  .card-hover {
    width: 80%;
  }

  #counter {
    display: none;
  }

  #random {
    display: none;
  }

  #duck {
    display: none !important;
  }

  #space {
    display: none !important;
  }
}

#text .text {
  font-size: 14px;
}

#name {
  font-size: 30px;
  font-style: italic;
  display: block;
}

#logo {
  width:20px;
  margin: 1%;
}

#counter {
  position: absolute;
  font: 12px;
  max-height:42px;
  min-width: 30px;
  right: 60.5%;
  top: 35.6%; 
  z-index: 6;
  text-align: center;
  padding-bottom: 30px;
}


#random {
  position: absolute;
  font: 12px;
  max-height:42px;
  min-width: 30px;
  left: 60.5%;
  top: 35.6%; 
  z-index: 6;
  text-align: center;
  padding-bottom: 30px;
}

:root {
  --drop-height: 120px;
  --logo-size: 50px;
  --topOff: 45%;
  --rightOff: 65%;
  --z: 10;

  --card-size: 45%;
}

.left-align {
  text-align: left;
}

#duck {
  display: block;
  z-index: var(--z);
  position: absolute;
  right: var(--rightOff);
  top: var(--topOff);
  
  animation-name: bounce; 
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.795, 0.135);
  animation-iteration-count: infinite;
}

#btn {
  width: 50px;
  height: 50px;
}

#zoom-duck {
  z-index: 12;
  width: 40px;
  position: absolute;
  left: 0px;
  
  animation-duration: 4s;
  animation-name: goRight;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes goRight {
  from {
    transform: translate(0,0);
  }

  to {
    transform: translate(97vw,0);
  }
}

#space {
  z-index: 1000000;
  position: absolute;
  font-size: 10px;
  outline: none;
  border: none;
  filter: drop-shadow(5px 5px 4px rgb(33, 33, 33));
  background-color: #d56262;

  height: 100px;
  padding: 1%;
}

#duck:hover {
  animation-name: spin;
}

@keyframes bounce {
  from { 
    transform: translateY(0) scale(1);
  }
  to   { 
    transform: translateY(var(--drop-height)) scale(1, 0.7);
  }
}
